.App {
  background-image: url('../public/bg-fx.png'); /* Adjust path if necessary */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0566c7;
  height: 100vh; /* Full height for the first page container */
  z-index: 0;
}

/* Add custom CSS for Wonder Boys font here */
@font-face {
  font-family: 'Wonder Boys';
  src: url('../public/WonderBoys.woff2') format('woff2'),
      url('../public/WonderBoys.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wonder Boys';
  src: url('../public/WonderBoys.woff2') format('woff2'),
      url('../public/WonderBoys.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: 'Wonder Boys', sans-serif;
}

.header-image {
  width: 100%;      /* Make the SVG width responsive */
  height: auto;     /* Maintain the aspect ratio */
  max-width: 1200px; /* Optionally set a maximum width */
  display: block;   /* To remove any extra space below the image */
  margin: auto;     /* Center the image horizontally */
  transition: transform 0.3s ease;
}

.header-image:hover {
  transform: scale(1.10); /* Scale up by 15% */
}

/* Space between Navbar and Main Header */
.navbar-custom {
  margin-bottom: 90px; /* Adjust this value as needed */
}

.navbar-brand-custom {
  margin-right: 20px; /* Adjust this value as needed */
}

.navbar-collapse-custom {
  margin-left: 20px; /* Adjust this value as needed */
}

.main-header, .next-header {
  position: relative; /* Needed for z-index to work */
  z-index: 2;
  /* Other styles... */
}

/* Space between "ENTER THE WORLD OF L3GO" and the next header */
.main-header {
  margin-bottom: 38px; /* Adjust this value as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-image, .parallax-image-left, .parallax-image-right, .lcol-low, .lcol-left{
  animation: fadeIn 1.5s ease-out;
}

.next-header {
  z-index: 2;
}

.shadow-text {
  color: white;
  font-size: 45px;
  text-shadow: 2px 2px 4px #000000; /* Drop shadow effect */
}

/* Navbar Styles */
.nav-link {
  color: #FAC715;
  font-size: 48px; /* Increased font size */
  margin-right: 35px; /* Space between items */
}

.nav-link:hover, .nav-link.active {
  color: #000000; /* Black on hover and when active */
}

.enter-section {
  margin-top: 50px; /* Adjust as needed */
}

.enter-text, .v-icon .l3go-left{
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  display: inline-block; /* Aligns text and icon */
}

.enter-text {
  display: block; /* Make it a block element */
  width: max-content; /* Fit to content width */
  margin: 20px auto; /* Center horizontally and add margin */
  padding: 10px 20px; /* Padding inside the button */
  background-color: #fac715; /* Example background color */
  color: white; /* Text color */
  text-align: center;
  border-radius: 30px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  font-size: 30px; /* Font size */
  border: none; /* No border */
  cursor: pointer; /* Cursor changes to pointer */
  z-index: 2;
}

.v-icon {
  width: auto; /* Adjust the width as needed to match the ENTER text width */
  fill: #FAC715; /* Color for SVG icon */
  cursor: pointer; /* Indicates clickable */
  z-index: 2;
}

.enter-text:hover {
  transform: scale(1.25); /* Increase size by 25% on hover */
  background-color: #000000;
}

.v-icon:hover{
  transform: scale(1.25); /* Increase size by 25% on hover */
  color:#000000
}

.connect-button {
  background-color: #FAC715;
  border-radius: 30px !important; /* Ensuring border-radius is applied */
  padding-left: 20px;
  padding-right: 20px;
  font-size: 30px;
  height: auto;
   color: white; 
}

.connect-button:hover {
  background-color: black; /* Black background on hover */
  color: white; /* Ensuring text remains white */
}

.connect-button:active {
  background-color: #000000; /* Black background when active */
}

.connect-button:active:hover {
  background-color: #FAC715; /* Revert to original color on hover when active */
}

.full-screen-section {
  min-height: 100vh; /* 100% of the viewport height */
  position: relative; /* For z-index and positioning */
}

/* Parallax Images */
.parallax-image-left, .parallax-image-right {
  position: absolute;
  max-width: 100%;
  height: auto;
  z-index: 1;
  overflow: hidden;
  /* Other styles... */
}

.parallax-image-left {
  left: 10%; /* Adjust position */
  top: 70%;
  transform: translate(-50%, -50%);
}

.parallax-image-right {
  right: 10%; /* Adjust position */
  top: 40%;
  transform: translate(50%, -50%);
}

.l3go-section {
  position: relative;
  display: flex;
  background-color: #25c8ff;
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  color: #FAC715;
  font-family: 'Wonder Boys', sans-serif;
  max-height: 1000px;
}

.collection-image-left, .collection-image-right {
  position: absolute;
  height: 300px; /* Adjust the height as needed */
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  z-index: 1;
}

.collection-image-left {
  left: 0;
}

.collection-image-right {
  right: 0;
}

.l3go-left, .l3go-right {
  flex: 1;
  padding: 30px; /* Adjust padding as needed */
}

.l3go-right {
  color: white;
  z-index: 3;
}

.l3go-left{
  animation: fadeIn 1.5s ease-out;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  display: inline-block; /* Aligns text and icon */
  z-index: 3;
}

.l3go-left:hover {
  transform: scale(1.10); /* Increase size by 25% on hover */
}

.l3go-left h1 {
  text-align: center;
  font-size: 170px;
}

.l3go-right {
  text-align: left;
  font-size: 30px;
  padding: 30px; /* Keep some padding */
  flex: 1; /* Flex to take available space */
  display: flex; /* Use flex layout */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center content vertically */
}

.l3go-right p {
  white-space: pre-wrap; /* Allow normal text wrapping */
  margin: 0; /* Remove default margin */
  max-width: 800px; /* Max width for readability */
  line-height: 1.5; /* Adjust line height for readability */
}

.lcol-container {
  display: flex;
}

.l3go-collection {
  display: flex;
  flex-direction: column;
  background-color: #F6C1B2; /* Yellow l3go*/
  color: white;
  font-family: 'Wonder Boys', sans-serif;
  border-bottom: 5px solid black;
}

.lcol-left, .lcol-right {
  flex: 1;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 5px solid black;
}

.l31-img {
  max-width: 70%;
  height: auto;
}

.lcol-right {
  background-color: #ffa052;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  text-align: center;
  font-size: 30px;
  border-left: 5px solid black;
}

.lcol-right h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 80px;
  transition: transform 0.3s ease;
}

.lcol-right h1:hover {
  transform: scale(1.10);
}

.lcol-right p {
  white-space: pre-wrap;
  margin: 0;
  margin-bottom: 20px;
  max-width: 800px;
  line-height: 1.5;
}

.lcol-low {
  background-color: #FAC715;
  padding-top: 35px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center; /* Center the images */
  flex-wrap: wrap;
  width: 100%;
  gap: 10px; /* Adjustable gap between images */
}

.lcol-low img {
  width: 300px; /* Fixed width for each image */
  height: auto; /* Maintain aspect ratio */
  margin: 5px; /* Margin for spacing */
  transition: transform 0.3s ease;
}

.lcol-low img:hover {
  transform: scale(1.15);
}

.l3goverse {
  display: flex;
  flex-direction: row; /* Set to row to align children side by side */
  background-color: #25c8ff; /* blue l3go */
  color: white;
  font-family: 'Wonder Boys', sans-serif;
  border-bottom: 5px solid black;
}

.l3goverse-left, .l3goverse-right {
  flex: 1; /* Each child takes up half the space */
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l3goverse-left {
  background-color: #ffa052;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  text-align: center;
  font-size: 30px;
  border-right: 5px solid black;
}

.l3goverse-left h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 80px;
  transition: transform 0.3s ease;
}

.l3goverse-left h1:hover {
  transform: scale(1.10);
}

.l3goverse-left h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 50px;
}

.l3goverse-left p {
  white-space: pre-wrap;
  margin: 0;
  margin-bottom: 20px;
  max-width: 800px;
  line-height: 1.5;
}

.l3verse {
  max-width: 70%;
  height: auto;
}

.discover-button {
  display: block; /* Make it a block element */
  width: max-content; /* Fit to content width */
  margin: 20px auto; /* Center horizontally and add margin */
  padding: 10px 20px; /* Padding inside the button */
  background-color: #25c8ff; /* Example background color */
  color: white; /* Text color */
  text-align: center;
  border-radius: 30px; /* Rounded corners */
  text-decoration: none; /* Remove underline from link */
  font-size: 24px; /* Font size */
  border: none; /* No border */
  cursor: pointer; /* Cursor changes to pointer */
}

.discover-button:hover {
  background-color: #000000; /* Change color on hover */
}

/* ROADMAP SECTION */
.roadmap {
  position: relative; /* Needed for absolute positioning of the progress bar */
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Wonder Boys', sans-serif;
  background-color: #F6C1B2;
  border-bottom: 5px solid black;
  overflow: hidden; /* To prevent overflow issues */
}

.roadmap-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  position: relative; /* To ensure they appear above the progress bar */
  z-index: 1; /* Higher than the progress bar */
}

.roadmap-section.start {
  animation-delay: 0.3s; /* Delay the start of the animation */
}

.roadmap-section.mid {
  animation-delay: 0.6s; /* Delay the start of the animation for mid section */
}

.roadmap-section.end {
  animation-delay: 0.9s; /* Delay the start of the animation for end section */
}

.roadmap-text, .roadmap-image-container {
  flex: 1; /* Each child takes up half the space */
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap-text {
  display: flex;
  flex-direction: column;
  align-items: right; /* Center children horizontally */
  text-align: right;
  font-size: 30px;
  max-width: 800px; /* Limit width for readability */
}

.roadmap-text h2 {
  font-size: 50px; /* Adjust header font size */
  margin-bottom: 20px;
}

.roadmap-text p {
  white-space: pre-wrap;
  line-height: 1.5;
}

.roadmap-image-container {
  max-width: 70%; /* Adjust image size */
  height: auto;
}

/* Additional styling for 'Mid' section */
.roadmap-section.mid .roadmap-text {
  align-items: center; /* Center text for 'Mid' section */
  text-align: left;
}

.roadmap-progress-bar {
  position: absolute;
  left: 50%;
  top: 0;
  width: 5px;
  height: 0%; /* Initial height as 0% */
  background-color: black;
  transform: translateX(-50%);
  z-index: 0; /* Behind the content */
}

.roadmap-circle {
  position: absolute;
  left: calc(50% - 12px); /* Center the circle */
  width: 25px;
  height: 25px;
  background-color: black;
  border-radius: 50%;
  visibility: hidden; /* Hidden by default */
}

/* TOKEN */

.token-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Wonder Boys', sans-serif;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  border-bottom: 5px solid black;
  background-image: url('../public/tknbg.png'); /* Replace with the path to your mobile l3goverse image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.token-image {
  max-width: 100%; /* Adjust as needed */
  height: auto;
  width: 600px;
  transform: translate(0, 0); /* Initial position */
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

.token-header {
  color: #FAC715;
  font-size: 120px;
  margin-top: -90px;
  margin-bottom: 10px;
  -webkit-text-stroke: 6px black;
  animation: fadeIn 1.5s ease-out;
  transition: transform 0.3s ease;
}

.token-header:hover {
  transform: scale(1.15);
}

.token-subheader {
  font-size: 50px;
  margin-bottom: 20px;
  animation: fadeIn 1.5s ease-out;
  transition: transform 0.6s ease;
}

.token-subheader:hover {
  transform: scale(1.10);
}

.token-paragraph {
  max-width: 800px; /* Limit width for readability */
  font-size: 25px;
  margin-bottom: 30px;
  line-height: 1.5;
}

.token-button {
  display: inline-block;
  background-color: #FAC715;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 30px;
  font-size: 30px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.token-button:hover {
  background-color: black;
  color: white;
  transform: scale(1.25);
}

/* TEAM Section */
.team-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffa052;
  color: white;
  font-family: 'Wonder Boys', sans-serif;
  padding: 30px;
  text-align: center;
  border-bottom: 5px solid black;
}

.team-header {
  font-size: 120px;
  margin-bottom: 10px;
  animation: fadeIn 1.5s ease-out;
  transition: transform 0.3s ease;
}

.team-header:hover {
  transform: scale(1.15);
}

.team-members {
  padding-top: 35px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center; /* Center the images */
  flex-wrap: wrap;
  width: 100%;
  gap: 150px; /* Adjustable gap between images */
  margin-bottom: 20px;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0;
  animation: fadeIn 1.5s ease-out;
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: scale(1.15);
}

.team-image {
  width: 400px; /* Fixed width for each image */
  height: auto; /* Maintain aspect ratio */
  margin: 5px; /* Margin for spacing */
  border: 5px solid white;
}

.team-subheader {
  font-size: 40px;
  margin-bottom: 10px;
}

.team-paragraph {
  font-size: 20px;
}

.footer {
  background-color: #0566c7; /* Darker blue color */
  border-top: 5px solid black;
}

.footer-heading {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #FAC715
}

.footer-link {
  color: white;
  font-size: 2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #FAC715; /* Hover color */
}

/* Footer Credits */
.footer p {
  font-size: 1rem;
}

.footer-logo-container {
  display: flex;
  align-items: center; /* Vertically center the logo */
  justify-content: center; /* Horizontally center the logo */
}

.footer-logo {
  max-width: 200px; /* Adjust the size as needed */
  height: auto;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Adjust font size for medium screens */
@media (max-width: 1400px) {
  .next-header .shadow-text {
    font-size: 40px; /* Slightly smaller font size */
  }

  .navbar-brand-custom img {
    height: 60px; /* Smaller logo */
  }

  .nav-link {
    font-size: 36px; /* Decrease font size */
    margin-right: 25px;
  }

  .v-icon {
    height: 25px;
    z-index: 9999;
  }

  .connect-button {
    font-size: 24px;
  }

  .header-image {
    max-width: 900px;
  }

  .main-header {
    margin-bottom: 30px;
  }

  .enter-text a{
    font-size: 40px;
    z-index: 9999;
  }

  .l3go-left h1 {
    font-size: 140px;
  }

  .l3go-right {
    font-size: 28px;
  }
}

/* Adjustments for extra small screens (around 768px) */
@media (max-width: 768px) {
  .navbar-brand-custom img {
    height: 40px; /* Further smaller logo */
  }

  .nav-link {
    font-size: 25px; /* Further decrease font size */
    margin-right: 15px;
  }

  .connect-button {
    font-size: 18px;
  }

  .header-image {
    max-width: 100%;
  }

  .main-header {
    margin-bottom: 20px;
  }

  .full-screen-section .enter-text,
  .full-screen-section .v-icon {
    position: relative;
    z-index: 2 !important;
  }

  .enter-text {
    font-size: 25px;
  }

  .shadow-text {
    font-size: 26px !important;
  }

  .l3go-section {
    flex-direction: column; /* Stack the sections vertically */
  }

  .l3go-left, .l3go-right {
    flex: none; /* Remove flex grow/shrink */
    width: 100%; /* Full width for each section */
    max-width: none; /* Override any max-width set */
  }

  .l3go-left h1 {
    font-size: 100px;
  }

  .l3go-right {
    font-size: 25px;
    text-align: center;
  }

  .l3go-right p {
    font-size: 25px; /* Smaller font size for smaller screens */
    max-width: 100%; /* Allow paragraph to take full width of the container */
  }

  .l3go-collection {
    flex-direction: column;
  }

  .l3go-collection p {
    font-size: 23px;
  }

  .lcol-container {
    flex-direction: column; /* Stack children vertically */
    font-size: 20px;
  }

  .lcol-right, .l3goverse-left {
    width: 100%;
    border-left: 0px;
  }

  .lcol-left, .l3goverse-right {
    display: none;
  }

  .lcol-low img {
    flex: 0 1 calc(50% - 10px); /* Two images per row */
  }

  .l3go-left, .l3go-right, .lcol-left, .lcol-right, .l3goverse-left, .l3goverse-right {
    width: 100%;
    max-width: none;
  }

  .l3go-right, .lcol-right, .l3goverse-left, .l3goverse-right {
    text-align: center;
  }

  .l3go-right p, .lcol-right p, .l3goverse-left, .l3goverse-right {
    max-width: 100%;
  }

  .l3goverse-left {
    border-right: 0px;
    font-size: 23px;
    background-image: url('../public/l3versemb-img.png'); /* Replace with the path to your mobile l3goverse image */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .roadmap-section {
    flex-direction: column; /* Stack items vertically */
  }

  .roadmap-text, .roadmap-image-container {
    width: 100%; /* Full width for each child */
    max-width: none; /* Override max-width */
    align-items: center;
    text-align: center;
  }

  .roadmap-text {
    margin-bottom: 18px; /* Add space between text and image */
  }

  .roadmap-text-mid {
    text-align: center;
    align-items: center;
    font-size: 23px;
  }

  .roadmap-progress-bar, .roadmap-circle {
    display: none;
  }

  .roadmap-image-container {
    order: 1; /* This will move the image to the top */
  }

  .roadmap-text {
    order: 2; /* This will place the text content below the image */
  }

  .token-section {
    padding: 10px;
    text-align: center;
    background-image: url('../public/tknmb.png'); /* Replace with the path to your mobile l3goverse image */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .token-image {
    max-width: 100%; /* Full width for smaller screens */
    z-index: 0;
  }

  .token-header {
    font-size: 60px; /* Smaller font size for header */
    margin-bottom: 30px;
    -webkit-text-stroke: 1px black;
    z-index: 2;
  }

  .token-subheader {
    font-size: 30px; /* Smaller font size for subheader */
    margin-bottom: 15px;
  }

  .token-paragraph {
    max-width: 100%; /* Full width for text */
    margin-bottom: 20px;
    font-size: 18px; /* Smaller font size for paragraph */
  }

  .token-button {
    padding: 8px 16px;
    font-size: 26px; /* Smaller font size for button */
  }

  .team-header {
    font-size: 60px;
    margin-bottom: 20px;
  }

  .team-members {
    flex-direction: column;
    align-items: center;
  }

  .team-members img{
    width: 300px;
  }

  .team-member {
    margin-bottom: 10px;
  }

  .team-subheader {
    font-size: 40px;
  }

  .team-paragraph {
    font-size: 25px;
  }
}