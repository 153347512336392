.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FAC715;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .animation-window {
    width: 400px; /* Adjust as needed */
    height: 400px; /* Adjust as needed */
    /* Add other styling as required */
    margin-bottom: -50px; /* Adjust this value to control spacing */
  }
  
  .loading-bar-container {
    width: 300px; /* Fixed width */
    height: 20px;
    background-color: #007db6;
    border-radius: 10px; /* Optional for rounded corners */
  }
  
  .loading-bar {
    width: 0;
    height: 100%;
    background-color: #003c58;
    border-radius: 10px;
    animation: fillBar 3s forwards;
  }
  
  @keyframes fillBar {
    to { width: 100%; }
  }
  